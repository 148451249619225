
<template>
    
        <div class="">
            <custom-alert v-if="displayAlert==true" :message="alertMessage"
                          v-on:showedAlert="resetAlert"></custom-alert>
            <div class="row">
                <div class="col-md-12 mt-3 mb-3">
                    <div class="card club-info official-overflow-hidden">
                        <div class="card-header register-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3 class="">{{ this.getSiteProps('general.admin-team') || 'Club' }} Official Roster</h3>
                                </div>
                                <div class="col-md-6 text-right" v-if="subdomain != 'alliancefastpitch' ">
                                    <b-button variant="primary btn-darkblue mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                                        <i class="fas fa-sliders-h"></i> Filters
                                    </b-button>
                                    <b-button variant="outline-primary" @click="refreshList" v-if="filtersApplied">
                                        <i class="fas fa-redo"></i> Reset
                                    </b-button>
                                    <b-button variant="outline-primary" @click="exportPDF" :disabled="exportProgress">
                                        <i class="fa" :class="exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-export'"></i> Export
                                    </b-button>
                                    <b-button variant="outline-primary refresh" :disabled="safesportRefreshing" style="margin-left:4px" @click="loadRostersInfo(true)" class="mr-1">
                                        <i class="fa" :class="safesportRefreshing ? 'fa-circle-notch fa-spin' : 'fas fa-sync-alt'"></i> Safe Sport Refresh
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="memberdetail-section" id="club-official-roster-wrap">
                                <div class="row juniordevlopment">
                                    <div class="col-md-12 pl-0 pr-0">
                                      <div v-if="getError" class="alert alert-danger">
                                        Error Occurred while fetching the information; please contact administration.
                                      </div>
                                        <div class="primary-contact">
                                            <div id="list_table" class="table table-sm table-bordered table-hover" style="width:100%; height:100%"></div>
                                            <b-overlay :show="showLoader" no-wrap></b-overlay>
                                            <b-table class="d-none"
                                                id="club-official-roster"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :items="rostersInfo"
                                                :fields="visibleFields"
                                                show-empty
                                                :outlined=true
                                                sticky-header="450px"
                                                no-border-collapse
                                                responsive
                                            >
                                                <template #head(member_status)="data">
                                                    {{ data.label }} <a href="javascript:void(0);" v-b-modal.statusInfo><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" v-if="subdomain != 'alliancefastpitch' "/></a>
                                                </template>
                                                <template #head(background_screening)="data">
                                                    {{ data.label }} 
                                                    <template v-if="getSiteProps('tooltips.backgroundScreening')">
                                                        <div class="d-inline" id="bgscreen-tooltip" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }">
                                                            <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                                                        </div>
                                                        <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip" class="white">                                        
                                                            <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                                                        </b-tooltip>
                                                    </template>
                                                </template>
                                              <template #empty="scope">
                                                  <h4>{{ scope.emptyText }}</h4>
                                              </template>
                                              <template #cell(official_data.RatingInfobyPersonID)="row">
                                                <b-table
                                                    :id="'rating_detail'+row.index"
                                                    :items="row.value"
                                                    :fields="ratingTblFields"
                                                    hover
                                                    show-empty
                                                    :outlined=true
                                                    :small=true
                                                    :sort-by.sync="ratingSortBy"
                                                    :sort-desc.sync="ratingSortDesc"
                                                >
                                                  <template #empty="scope">
                                                    <h4>{{ scope.emptyText }}</h4>
                                                  </template>
                                                  <template #cell(division)="row">
                                                    {{ row.item.Rating.split(": ")[2] }}
                                                  </template>
                                                  <template #cell(rating_type)="row">
                                                    {{ row.item.Rating.split(": ")[0] }}
                                                  </template>
                                                  <template #cell(level)="row">
                                                    {{ row.item.Rating.split(": ")[1] }}
                                                  </template>
                                                  <template #cell(EventAbbreviation)="row">
                                                    {{ eventName[row.value] }}
                                                  </template>
                                                  <template #cell(ExpirationDate)="row">
                                                    <span v-html="row.value"></span>
                                                  </template>
                                                </b-table>
                                              </template>
                                              <template #cell(official_data.OfficialCertificationsViewfortheWeb)="row">
                                                <b-table
                                                    :id="'certifications'+row.index"
                                                    :items="row.value"
                                                    :fields="certsTblFields"
                                                    hover
                                                    show-empty
                                                    :outlined=true
                                                    :small=true
                                                    :sort-by.sync="certsSortBy"
                                                    :sort-desc.sync="certsSortDesc"
                                                >
                                                  <template #empty="scope">
                                                    <h4>{{ scope.emptyText }}</h4>
                                                  </template>
                                                </b-table>
                                              </template>

                                            </b-table>
                                        </div>
                                        <b-modal id="statusInfo" ref="statusInfo" size="xl" title="Membership Status Types" hide-footer>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Status</th>
                                                            <th>What This Means</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Current</td>
                                                            <td>Paid dues, Signed Waiver, Completed Safe Sport training </td>
                                                            <td>All membership requirements are met and may participate in all sanctioned activities.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Expired</td>
                                                            <td>Dues not paid</td>
                                                            <td>Member needs to renew as an active (insured) member to be eligible to participate in sanctioned activity.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Waiting – Need Safe Sport</td>
                                                            <td>Paid dues, Signed Waiver but has not completed Safe Sport training </td>
                                                            <td>Membership is pending successful completion of Safe Sport training within 30 days of joining as a new member. May still participate in sanctioned activities during this period. </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pending – Safe Sport </td>
                                                            <td>Paid dues, Signed Waiver but has not completed Safe Sport training after 30 days</td>
                                                            <td>Not eligible to participate in sanctioned events (tournaments) until Safe Sport training is completed. The 30-day grace period to complete Safe Sport training after joining as a new member has expired. </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pending – Signed Waiver </td>
                                                            <td>Paid dues but has not signed Waiver</td>
                                                            <td>Membership is pending signed Annual Membership Waiver. Not eligible to participate in sanctioned activity until waiver is signed. Please go into your individual membership dashboard to sign the Waiver.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pending – Safe Sport & Signed Waiver</td>
                                                            <td>Paid dues but has not signed Waiver or completed Safe Sport training</td>
                                                            <td>Membership is pending successful completion of Safe Sport Training and signed Annual Membership Waiver. Not eligible to participate until Waiver is signed. Please go into your individual membership dashboard to sign the Waiver. New members have 30 days to complete Safe Sport training.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Suspended</td>
                                                            <td>N/A</td>
                                                            <td>Ineligible to participate in any capacity with any USA Water Ski & Wake Sports club or in any USA Water Ski & Wake Sports sanctioned activity.</td>
                                                        </tr>                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </b-modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                <form ref="form" method="post" @submit.prevent="advancedSearch()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Membership Expiration From Date</label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="searchForm.member_expiry_start_date"
                                            :format="'MM/DD/YYYY'"
                                            :displayFormat="'MM/DD/YYYY'"
                                            v-mask="'##/##/####'"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                                <input id="member_expiry_start_date" type="hidden" class="form-control" :value="member_expiry_start_date_1">
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Membership Expiration To Date</label>
                                <div class="input-group birthdate">
                                    <div class="birthdaypicker">
                                        <date-pick
                                            v-model="searchForm.member_expiry_end_date"
                                            :format="'MM/DD/YYYY'"
                                            :displayFormat="'MM/DD/YYYY'"
                                            v-mask="'##/##/####'"
                                            :parseDate="parseDatePick"
                                        ></date-pick>
                                    </div>
                                </div>
                                <input id="member_expiry_end_date" type="hidden" class="form-control" :value="member_expiry_end_date_1">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">SafeSport Status</label>
                                <select id="safesport_status" class="form-control" v-model="searchForm.safesport_status">
                                    <option value="">Select</option>
                                    <option value="0">Not Started</option>
                                    <option value="1">In Progress</option>
                                    <option value="3">Complete</option>
                                    <option value="13">Expired</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Membership Status</label>
                                <select id="membership_status" class="form-control" v-model="searchForm.membership_status">
                                    <option value="">Select</option>
                                    <template v-for="(id, val) in membershipStatus">
                                        <option v-if="val > 0" :value="val" :key="val">{{ id }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-12 modal-searchbtn text-center">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary btn-blue">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>
        </div>
</template>

<script>
import DatePick from "vue-date-pick";
import axios from 'axios';
import $ from 'jquery';
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';
import moment from 'moment';

export default {
    name: "clubofficialroster",
    components: { DatePick },
    data() {
        return {
            hoverTime:false,
            bgToolTipInfo:'',
            exportProgress: false,
            safesportRefreshing: false,
            rostersInfo:[],
            table:null,
            sortBy: 'last_name',
            sortDesc: false,
            getError: false,
            filtersApplied: false,
            tblFields: [
                { field: 'last_name', sortable: true, style:'font-weight:700', title: 'Last Name', stickyColumn: true, isRowHeader: true,frozen:true, cssClass: "custom-column-style"  },
                { field: 'first_name', sortable: true, title: 'First Name', stickyColumn: true, isRowHeader: true,frozen:true, cssClass: "custom-column-style"  },
                { field: 'email', sortable: true, title: 'Email', stickyColumn: true, isRowHeader: true, cssClass: "custom-column-style" },
                { field: 'confirmation_code', sortable: true, title: 'Member #', stickyColumn: true, isRowHeader: true, cssClass: "custom-column-style" },
                { field: 'membership_name', sortable: true, title: "Membership Type" },
                { field: 'membership_endtate', sortable: true, title: "Membership Expiration" },
                { field: 'member_status', headerSort: true, title: "Membership Status <span class='tabuTool' v-if='"+this.subdomain+" != alliancefastpitch'><img src='/dist/img/memberregistration/tooltip-icon-small.png' alt='tooltipicon' style='width: 20px'/></span>", headerMouseLeave: this.exitTime, headerMouseOver: this.headerClickHandler}, 
                // { field: 'sports_discipline', sortable: true, title: 'Primary Sports Discipline' },
                { field: 'safesport', sortable: false, title:"Safe Sport Training Status / Expiration" },
                { field: 'background_screening', headerSort:true, title:"Background Screening Status / Expiration  <img src='/dist/img/memberregistration/tooltip-icon-small.png' alt='tooltipicon' style='width: 20px'/>", headerTooltip:function(e, cell, onRendered){

                    var el = document.createElement("div");
                    el.style.backgroundColor = "black";
                    el.style.color = "white";
                    el.innerHTML = window.altThis.bgToolTipInfo;
                    return el; 
                }},
                { field: 'mvr', sortable: false, title:"MVR Status / Expiration" },
            ],
            ratingTblFields:[
                { key: 'division', sortable: true, label: 'Division' },
                { key: 'rating_type', sortable: true, label: 'Rating' },
                { key: 'level', sortable: true, label: 'Level' },
                { key: 'EventAbbreviation', sortable: true, label: 'Event' },
                { key: 'Effective_Date', sortable: true, label: 'Eff. From', formatter: "usdate" },
                { key: 'ExpirationDate', sortable: true, label: 'Expiration', formatter: "usdate" },
            ],
            ratingSortBy:'ExpirationDate',
            ratingSortDesc:true,
            certsTblFields:[
                { key: 'Certification', sortable: true, label: 'Certification' },
                { key: 'EffectiveFromDate', sortable: true, label: 'Eff. From', formatter: "usdate"},
                { key: 'EffectiveToDate', sortable: true, label: 'Expiration', formatter: "usdate"},
            ],
            certsSortBy: 'EffectiveToDate',
            certsSortDesc: true,
            eventName:{
                '':'None',
                'N':'None',
                'S':'Slalom',
                'T':'Trick',
                'J':'Jump'
            },
            showLoader:true,
            searchForm: {
                member_expiry_start_date: '',
                member_expiry_end_date: '',
                membership_status: '',
                safesport_status: ''
            }
        }
    },
    methods: {
        loadRostersInfo(safesportRefresh = false){
            let url = this.basePath + 'api/club/official_roster';
            if(this.filtersApplied){
                let params = [];
                params.push(`member_expiry_start_date=${this.searchForm.member_expiry_start_date}`);
                params.push(`member_expiry_end_date=${this.searchForm.member_expiry_end_date}`);
                params.push(`membership_status=${this.searchForm.membership_status}`);
                params.push(`safesport_status=${this.searchForm.safesport_status}`);
                url += `?` + params.join('&');
            }
            this.showLoader=true;
            if(safesportRefresh){
                this.safesportRefreshing = true;
                url += "?safe_sport_refresh=true"
            } 
            axios.get(url, {headers: this.clubHeaders})
                .then(function (response) {
                    const resp = response.data.data;
                  if(response.data.message!="Unauthorized parameter UserId from this token"){
                      this.rostersInfo = resp;
                      this.safesportRefreshing = false;
                    }
                    else{
                      this.getError = true;
                    }
                  this.showLoader=false;
                  this.table = new Tabulator('#list_table',{
                      height:"100%",
                      pagination:"local",
                      paginationSize:10,
                      paginationSizeSelector:[10, 25, 50, 100],
                      paginationCounter:"rows",
                      columns:this.tblFields,
                      data: this.rostersInfo,


                        rowFormatter:function(row){
                                //create and style holder elements
                            var holderEl = document.createElement("div");
                            var tableEl = document.createElement("div");

                            holderEl.style.boxSizing = "border-box";
                            holderEl.style.padding = "10px 30px 10px 10px";
                            holderEl.style.borderTop = "1px solid #999";
                            holderEl.style.borderBotom = "1px solid #999";                            
                            tableEl.style.border = "1px solid #999";
                            holderEl.appendChild(tableEl);

                            row.getElement().appendChild(holderEl);

                            var subTable = new Tabulator(tableEl, {
                                maxHeight:"500px",
                                layout:"fitDataTable",
                                columnDefaults:{
                                    resizable:false,
                                },
                                data:row.getData().official_data.RatingInfobyPersonID,
                                columns:[
                                    { title: 'Ratings', style:"padding-left:0 !important", cssClass:'subHeaderCls',
                                    columns:[
                                    { field: 'Rating', sortable: true, title: 'Division', formatter:function(cell, formatterParams){
                                        var value = cell.getValue();
                                                return value.split(": ")[2] || "N/A";
                                        }},
                                    { field: 'Rating', sortable: true, title: 'Rating', formatter:function(cell, formatterParams){
                                        var value = cell.getValue();
                                                return value.split(": ")[0] || "N/A";
                                        }},
                                    { field: 'Rating', sortable: true, title: 'Level', formatter:function(cell, formatterParams){
                                        var value = cell.getValue();
                                                return value.split(": ")[1] || "N/A";
                                        }},
                                    { field: 'EventAbbreviation', sortable: true, title: 'Event' },
                                    { field: 'Effective_Date', sortable: true, title: 'Eff. From', formatter:function(cell, formatterParams){
                                        var value = cell.getValue();
                                                return moment(value).format("MM/DD/YYYY") || "N/A";
                                        }},
                                    { field: 'ExpirationDate', sortable: true, title: 'Expiration', formatter:function(cell, formatterParams){
                                        var value = cell.getValue();
                                                return moment(value).format("MM/DD/YYYY") || "N/A";
                                        }},
                                    ]
                                    }
                                ]
                            })
                        },
                  });
                  
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                    this.safesportRefreshing = false;
                });
        },
        exportCsv(){
            this.exportProgress = true;
            var self = this;
            let url = process.env.VUE_APP_URL + "api/club/official-roasters/export";
            if(this.filtersApplied){
                let params = [];
                params.push(`member_expiry_start_date=${this.searchForm.member_expiry_start_date}`);
                params.push(`member_expiry_end_date=${this.searchForm.member_expiry_end_date}`);
                params.push(`membership_status=${this.searchForm.membership_status}`);
                params.push(`safesport_status=${this.searchForm.safesport_status}`);
                url += `?` + params.join('&');
            }
            $.ajax({
                url: url,
                type: 'post',
                headers: self.clubHeaders,
                responseType: 'blob',
                // "data": this.table.ajax.params(),
                beforeSend: function(){
                    self.exportProgress = true;
                },
                "success": function(res, status, xhr) {
                    self.exportProgress = false;
                    if(res.file_url != undefined) window.open(res.file_url, '_blank');
                },
                error: function(err){
                    console.log(err);
                    self.exportProgress = false;
                }
            });
        },
        exportPDF(){
            this.exportProgress = true;
            axios.get(this.basePath + 'api/club/official-rosters/export/pdf', {headers: this.clubHeaders, responseType: 'blob'})
            .then(function (response) {
                // window.open(URL.createObjectURL(response.data));
                this.exportProgress = false;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'officials-roster.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }.bind(this))
            .catch(function (error) {
                this.exportProgress = false;
                console.log(error);
            });
        },
        advancedSearch() {
            this.filtersApplied = true;
            this.loadRostersInfo();
            this.$refs['modal-member-report'].hide();
        },
        refreshList(event){
            this.searchForm = {
                member_expiry_start_date: '',
                member_expiry_end_date: '',
                membership_status: '',
                safesport_status: ''
            };
            this.loadRostersInfo();
            this.filtersApplied = false;
        },
        headerClickHandler(column) {
            this.hoverTime = true;
            setTimeout(()=>{
                    if(this.hoverTime){
                        this.$refs['statusInfo'].show();
                        this.hoverTime = false;
                    }
            },2000);
        },
        exitTime(){
            this.hoverTime = false;
        },
        bgToolTipInf(){
            this.bgToolTipInfo = this.getSiteProps('tooltips.backgroundScreening');
        }
    },

    mounted() {
        window.altThis = this;
        setTimeout(()=>{
            this.loadRostersInfo();
            this.bgToolTipInf();
        },1500);
        this.getMembershipStatus();
        this.clubInfoData = JSON.parse(localStorage.getItem("team"));
        this.enableModal('hide');
    },
    computed: {
        visibleFields() {
          return this.tblFields.filter(field => {
                if(this.subdomain == 'alliancefastpitch'){
                    if(['safesport','sports_discipline', 'background_screening', 'rating_certs', 'mvr', 'official_data.RatingInfobyPersonID'].indexOf(field.key) == -1) return field;
                } 
                else {
                    return field;
                }
          });
        }
    }
}
</script>

<style>
.custom-column-style{
    font-weight:700 !important;
}
.tabulator-row{
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
}
.tabulator .tabulator-header {
    background-color:white !important;
    border-bottom: none !important;
    }
.tabulator .tabulator-header .tabulator-col{
    background: white !important;
}
.tabulator-col, .tabulator-cell{
    padding:15px !important;
}

.subHeaderCls{
    padding:0 !important;
    align-items:center;
}

.card.official-overflow-hidden {
    overflow: hidden;
}
#club-official-roster .table-b-table-default{
  font-size: 13px;
}
#club-official-roster-wrap .b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
/*#club-official-roster.table.b-table.table-hover > tbody > tr:hover > .table-b-table-default,*/
/*#club-official-roster.table-hover tbody tr:hover{*/
/*  background-color: #fff !important;*/
/*  background-image: none;*/
/*}*/
</style>
